import api from './api'
import type {
  JsonApiListResponse,
  JsonApiResponse,
} from '@papershift/jsonapi/src/types'
import { transformQueryFilters, type FilterItem } from './filter-utils'
import { getNotImplemented, listNotImplemented } from './olympus'
import type { ProfilePermissions, User } from './user'

export function createUser(): Promise<JsonApiResponse<User>> {
  return getNotImplemented()
}

export function updateUser() {
  return getNotImplemented()
}
export function listUsers(): Promise<JsonApiListResponse<User>> {
  return listNotImplemented()
}

export function listUsersWithAccountRoles(): Promise<
  JsonApiListResponse<User>
> {
  return listNotImplemented()
}

export function listUsersByTeam(): Promise<JsonApiListResponse<User>> {
  return listNotImplemented()
}

export function listAvailableUsers(
  filters: FilterItem[]
): Promise<JsonApiListResponse<User>> {
  return api
    .url('/olympus/v1/users')
    .query({
      sort: 'name',
      include: 'account',
      ...transformQueryFilters(filters),
    })
    .get()
    .json()
}

export function getUser(): Promise<JsonApiResponse<User>> {
  return getNotImplemented()
}

export function getUserWithRolesInAccount(): Promise<JsonApiResponse<User>> {
  return getNotImplemented()
}

export function getUserWithRolesInTeam(): Promise<JsonApiResponse<User>> {
  return getNotImplemented()
}

export function deleteUser() {
  return getNotImplemented()
}

export function createInvite(): Promise<JsonApiResponse<User>> {
  return getNotImplemented()
}

export function deleteInvite(): Promise<JsonApiResponse<User>> {
  return getNotImplemented()
}

export function listProfilePermissions(
  userId: string
): Promise<JsonApiResponse<ProfilePermissions>> {
  return api.url(`/ui/v1/profile-permissions?user_id=${userId}`).get().json()
}

export function getUploadUrl(): Promise<
  JsonApiResponse<{ upload_url: string }>
> {
  return getNotImplemented()
}

export async function uploadFile(): Promise<void> {
  console.error('not implemented')
  return Promise.resolve()
}
