import type { JsonApiKeysetPaginatedListResponse } from '@papershift/jsonapi/src/types'
import api from './api'

export function listNextItems<
  Attrs,
  Meta,
  Response extends JsonApiKeysetPaginatedListResponse<
    Attrs,
    Meta
  > = JsonApiKeysetPaginatedListResponse<Attrs, Meta>,
>(nextPageLink: string): Promise<Response> {
  return api.url(nextPageLink).get().json()
}
