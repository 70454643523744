import api from './api'
import type {
  JsonApiKeysetPaginatedListResponse,
  JsonApiListResponse,
  JsonApiResponse,
} from '@papershift/jsonapi/src/types'
import { transformQueryFilters, type FilterItem } from './filter-utils'
import { getNotImplemented, listNotImplemented } from './olympus'
import type {
  Chat,
  ChatBookmark,
  ChatContextType,
  ChatMember,
  ChatMessage,
  MessageReaction,
} from './chat'

export function listChats(
  page: number = 1,
  filters: FilterItem[] = []
): Promise<JsonApiListResponse<Chat>> {
  return api
    .url('/olympus/v1/chats')
    .query({
      'page[number]': page,
      'meta[permissions]': 'actions',
      include: 'latest_message,bookmark,context',
      sort: '-open,-updated_at',
      ...transformQueryFilters(filters),
    })
    .get()
    .json()
}

export function listChatsByContext(
  contextId: string,
  contextType: ChatContextType
): Promise<JsonApiListResponse<Chat>> {
  return api
    .url('/olympus/v1/chats')
    .query({
      include: 'bookmark',
      'meta[permissions]': 'actions,chat_membership',
      'filter[context_type]': `eq:${contextType}`,
      'filter[context_id]': `eq:${contextId}`,
    })
    .get()
    .json()
}

export { createChat } from './chat'

export function getChat(chatId: string): Promise<JsonApiResponse<Chat>> {
  return api
    .url(`/olympus/v1/chats/${chatId}`)
    .query({
      include: 'bookmark',
      'meta[permissions]': 'actions,chat_membership',
    })
    .get()
    .json()
}

export function listMessagesByChat(
  chatId: string
): Promise<JsonApiKeysetPaginatedListResponse<ChatMessage>> {
  return api
    .url(`/olympus/v1/chats/${chatId}/messages`)
    .query({
      include: 'author',
    })
    .get()
    .json()
}

export function createChatMessage(
  chatId: string,
  content: string
): Promise<JsonApiResponse<ChatMessage>> {
  return api
    .url(`/olympus/v1/chats/${chatId}/messages`)
    .post({
      data: {
        type: 'message',
        attributes: {
          content,
        },
      },
    })
    .json()
}

export function listChatMembers(): Promise<JsonApiListResponse<ChatMember>> {
  return listNotImplemented()
}

export function updateChatBookmark(): Promise<JsonApiResponse<ChatBookmark>> {
  return getNotImplemented()
}

export async function updateChat(): Promise<JsonApiResponse<Chat>> {
  return getNotImplemented()
}

export async function updateChatMessage(): Promise<
  JsonApiResponse<ChatMessage>
> {
  return getNotImplemented()
}

export async function deleteChatMessage(): Promise<
  JsonApiResponse<ChatMessage>
> {
  return getNotImplemented()
}

export function listMessageReactions(): Promise<
  JsonApiListResponse<MessageReaction>
> {
  return listNotImplemented()
}
