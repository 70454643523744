import { ref } from 'vue'
import { defineStore } from 'pinia'
import useAuthStore from '../auth/auth.store'
import * as api from '@papershift/api/src/account'
import { flattenRecord } from '@papershift/jsonapi/src/utils'

const accountStore = defineStore(
  'account',
  () => {
    const authStore = useAuthStore()

    // list of all accounts the identity is associated with
    // each have a user object
    const accounts = ref<api.Account[]>([])

    function $resetState() {
      accounts.value = []
    }

    function setAccounts(accountsArray: api.Account[]) {
      accounts.value = accountsArray
    }

    async function createAccount(payload: api.AddAccountParams) {
      const response = await api.createAccount(payload)
      setAccounts(response.data.attributes.accounts)
      return response
    }

    async function updateCurrentAccount(payload: Partial<api.Account>) {
      const accountId = authStore.account?.id

      if (!accountId) throw new Error('Invalid account ID')

      const params: api.UpdateAccountParams = {
        id: accountId,
        ...payload,
      }

      await api.updateAccount(params)
    }

    async function getCurrentAccount() {
      const accountId = authStore.account?.id

      if (!accountId) throw new Error('Invalid account ID')

      const { data } = await api.getAccount(accountId)
      return flattenRecord(data)
    }

    async function fetchCurrentAccountSettingsPermissions() {
      const accountId = authStore.account?.id

      if (!accountId) throw new Error('Invalid account ID')

      const currentAccount = accounts.value.find(
        (item: api.Account) => item.id === accountId
      )

      const { data } = await api.listAccountPermissions()

      // as long as authStore.account.id is available,
      // currentAccount should not be null
      currentAccount!.permissions = {
        ...currentAccount!.permissions,
        ...data.attributes,
      }
    }

    async function setLastUsedAccount(accountId: string) {
      const { data } = await api.switchAccounts(accountId)
      return flattenRecord(data)
    }

    return {
      $resetState,

      accounts,

      setAccounts,
      createAccount,
      updateCurrentAccount,
      getCurrentAccount,
      setLastUsedAccount,
      fetchCurrentAccountSettingsPermissions,
    }
  },
  {
    persist: [
      {
        paths: ['accounts'],
        storage: localStorage,
      },
    ],
  }
)

export default accountStore
