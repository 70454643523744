import { mergeAttributes } from '@tiptap/vue-3'
import {
  isAllowedUri as isAllowedUriByDefault,
  type LinkOptions,
} from '@tiptap/extension-link'
import type { DOMOutputSpec } from '@tiptap/pm/model'

export default function useRichEditorLinks() {
  // This function is what TipTap does by default:
  // https://github.com/ueberdosis/tiptap/blob/3e59097b34ce8bc8c39e1def67eb31a1d9f9e5c2/packages/extension-link/src/link.ts#L301
  function isAllowedUri(
    options: LinkOptions,
    HTMLAttributes: Record<string, any>
  ) {
    return options.isAllowedUri(HTMLAttributes.href, {
      defaultValidate: (url) => !!isAllowedUriByDefault(url, options.protocols),
      protocols: options.protocols,
      defaultProtocol: options.defaultProtocol,
    })
  }

  function isPulseLink(href: string) {
    const linkDestination = new URL(href)
    return linkDestination.origin === window.location.origin
  }

  function processAttributes(
    options: LinkOptions,
    HTMLAttributes: Record<string, any>
  ) {
    let attrs = HTMLAttributes

    if (!isAllowedUri(options, HTMLAttributes)) {
      // prevent xss (also TipTap default - see link on the `isAllowedUri` function)
      attrs = { ...HTMLAttributes, href: '' }
    } else if (HTMLAttributes.href && isPulseLink(HTMLAttributes.href)) {
      attrs = { ...HTMLAttributes, target: null } // open Pulse links in same tab
    }

    return attrs
  }

  function renderHTML(
    this: { options: LinkOptions },
    { HTMLAttributes }: { HTMLAttributes: Record<string, any> }
  ): DOMOutputSpec {
    const attrs = processAttributes(this.options, HTMLAttributes)
    return ['a', mergeAttributes(this.options.HTMLAttributes, attrs), 0]
  }

  return {
    renderHTML,
  }
}
