import {
  IconAdjustmentsHorizontal,
  IconArchiveBoxXMark,
  IconArrowDownTray,
  IconArrowLeft16Solid,
  IconArrowLongLeft20Solid,
  IconArrowLongRight20Solid,
  IconArrowRight16Solid,
  IconArrowUturnRight,
  IconBars3,
  IconBuildingOffice,
  IconChatBubbleLeftEllipsis,
  IconChatBubbleLeftRight,
  IconCheck,
  IconCheckCircle,
  IconChevronDown20Solid,
  IconCog6Tooth,
  IconCreditCard,
  IconDocumentDuplicate20Solid,
  IconEllipsisVerticalSolid,
  IconEnvelopeSolid,
  IconExclaimationTriangle,
  IconEye20Solid,
  IconEyeSlash20Solid,
  IconFolderOpen,
  IconHome,
  IconInformationCircle,
  IconLifebuoy,
  IconMinusCircle,
  IconPencilSquare,
  IconPencilSquare20Solid,
  IconPlus,
  IconQueueList,
  IconRocketLaunch,
  IconSquare3Stack3d,
  IconSun,
  IconTrash,
  IconUserSolid,
  IconUsers,
  IconXCircle,
  IconXMark,
  IconPaperAirplane,
  IconShieldCheck,
  IconArrowUpOnSquare,
  IconDocumentText,
  IconArrowLongDown16Solid,
  IconWrenchScrewdriverSolid,
  IconLockClosed,
  IconLockOpen,
  IconFaceSmile,
  IconBolt,
} from '@iconify-prerendered/vue-heroicons'
import {
  IconDriveDocument,
  IconFile,
  IconFilePdfBox,
  IconFilePresentationBox,
  IconGoogleSpreadsheet,
  IconImage,
  IconClockOutline,
  IconMountain,
} from '@iconify-prerendered/vue-mdi'
import { IconDoorOpen } from '@iconify-prerendered/vue-bi'
import { IconSepa } from '@iconify-prerendered/vue-simple-icons'
import {
  IconAmex,
  IconMastercard,
  IconVisa,
} from '@iconify-prerendered/vue-logos'
import {
  IconBold,
  IconItalic,
  IconStrikethrough,
  IconUnderline,
  IconFormatClear,
  IconParagraph,
  IconH1,
  IconH2,
  IconH3,
  IconH4,
  IconH5,
  IconH6,
  IconListUnordered,
  IconListOrdered,
  IconCodeLine,
  IconCodeBlock,
  IconQuoteText,
  IconAlignLeft,
  IconAlignRight,
  IconAlignCenter,
  IconLink,
  IconListCheck3,
} from '@iconify-prerendered/vue-ri'

export const AdjustmentsHorizontalIcon = IconAdjustmentsHorizontal
export const ArchiveBoxXMarkIcon = IconArchiveBoxXMark
export const ArrowDownTrayIcon = IconArrowDownTray
export const ArrowLeftIcon = IconArrowLeft16Solid
export const ArrowLongLeftIcon = IconArrowLongLeft20Solid
export const ArrowLongRightIcon = IconArrowLongRight20Solid
export const ArrowRightIcon = IconArrowRight16Solid
export const ArrowUturnRightIcon = IconArrowUturnRight
export const Bars3Icon = IconBars3
export const BuildingOfficeIcon = IconBuildingOffice
export const ChatBubbleLeftEllipsisIcon = IconChatBubbleLeftEllipsis
export const ChatBubbleLeftRightIcon = IconChatBubbleLeftRight
export const CheckCircleIcon = IconCheckCircle
export const CheckIcon = IconCheck
export const ChevronDownIcon = IconChevronDown20Solid
export const Cog6ToothIcon = IconCog6Tooth
export const CreditCardIcon = IconCreditCard
export const DocumentDuplicateIcon = IconDocumentDuplicate20Solid
export const EllipsisVerticalIcon = IconEllipsisVerticalSolid
export const EnvelopeIcon = IconEnvelopeSolid
export const ExclamationTriangleIcon = IconExclaimationTriangle
export const EyeIcon = IconEye20Solid
export const EyeSlashIcon = IconEyeSlash20Solid
export const DriveDocumentIcon = IconDriveDocument
export const FileIcon = IconFile
export const FilePdfBoxIcon = IconFilePdfBox
export const FilePresentationBoxIcon = IconFilePresentationBox
export const GoogleSpreadsheetIcon = IconGoogleSpreadsheet
export const ImageIcon = IconImage
export const FolderOpenIcon = IconFolderOpen
export const HomeIcon = IconHome
export const InformationCircleIcon = IconInformationCircle
export const LifebuoyIcon = IconLifebuoy
export const MinusCircleIcon = IconMinusCircle
export const PencilSquareOutlineIcon = IconPencilSquare
export const PencilSquareSolidIcon = IconPencilSquare20Solid
export const PlusIcon = IconPlus
export const RocketLaunchIcon = IconRocketLaunch
export const Square3Stack3DIcon = IconSquare3Stack3d
export const SunIcon = IconSun
export const TrashIcon = IconTrash
export const UserIcon = IconUserSolid
export const UsersIcon = IconUsers
export const XCircleIcon = IconXCircle
export const XMarkIcon = IconXMark
export const PaperAirplaneIcon = IconPaperAirplane
export const ShieldCheckIcon = IconShieldCheck
export const ArrowUpOnSquareIcon = IconArrowUpOnSquare
export const ClockOutlineIcon = IconClockOutline
export const QueueListIcon = IconQueueList
export const DoorOpenIcon = IconDoorOpen
export const VisaIcon = IconVisa
export const MastercardIcon = IconMastercard
export const AmexIcon = IconAmex
export const SepaIcon = IconSepa
export const DocumentTextIcon = IconDocumentText
export const ArrowLongDownIcon = IconArrowLongDown16Solid
export const WrenchScrewdriverIcon = IconWrenchScrewdriverSolid
export const LockClosedIcon = IconLockClosed
export const LockOpenIcon = IconLockOpen
export const EditorIconAlignCenter = IconAlignCenter
export const EditorIconAlignLeft = IconAlignLeft
export const EditorIconAlignRight = IconAlignRight
export const EditorIconBold = IconBold
export const EditorIconCode = IconCodeLine
export const EditorIconCodeBlock = IconCodeBlock
export const EditorIconFormatClear = IconFormatClear
export const EditorIconH1 = IconH1
export const EditorIconH2 = IconH2
export const EditorIconH3 = IconH3
export const EditorIconH4 = IconH4
export const EditorIconH5 = IconH5
export const EditorIconH6 = IconH6
export const EditorIconItalic = IconItalic
export const EditorIconListOrdered = IconListOrdered
export const EditorIconListUnordered = IconListUnordered
export const EditorIconParagraph = IconParagraph
export const EditorIconQuoteText = IconQuoteText
export const EditorIconStrikethrough = IconStrikethrough
export const EditorIconUnderline = IconUnderline
export const MountainIcon = IconMountain
export const LinkIcon = IconLink
export const EditorIconChecklist = IconListCheck3
export const FaceSmileIcon = IconFaceSmile
export const BoltIcon = IconBolt
