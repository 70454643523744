import type { Ref } from 'vue'
import { differenceInCalendarDays } from 'date-fns'
import type { ChatMessage } from '@papershift/api/src/chat'

export default function useMessageUtils(messages: Ref<ChatMessage[]>) {
  const isFollowUpMessage = (message: ChatMessage, index: number) =>
    message.author_id === messages.value[index - 1]?.author_id

  const isNewDayMessage = (message: ChatMessage, index: number) => {
    const prevMessage = messages.value[index - 1]

    if (!prevMessage) return true

    const prevMessageDate = new Date(prevMessage.created_at)
    const messageDate = new Date(message.created_at)

    return differenceInCalendarDays(messageDate, prevMessageDate) > 0
  }

  return { isFollowUpMessage, isNewDayMessage }
}
