<script setup lang="ts">
import { toRef } from 'vue'
import { useI18n } from '@papershift/locale/src/i18n'
import { notify } from '@papershift/ui/src/Notifier'
import useActionTracker from '@papershift/action-status/src/action-tracker'
import useModalToggler from '@papershift/ui/src/utils/use-modal-toggler'
import useChatStore from '@/stores/chat/chat.store'
import Modal from '@papershift/ui/src/Modal.vue'
import ChatForm from './ChatForm.vue'
import type { Chat, ChatPayload } from '@papershift/api/src/chat'

const props = defineProps<{
  chatData: Chat | null
}>()

const emit = defineEmits<{
  close: []
}>()

const chat = toRef(props, 'chatData')

const { t } = useI18n()
const { updateChat } = useChatStore()
const $actions = useActionTracker({ updateChat: 'updateChat' })
const { isModalActive, toggleModal, onModalClose, modalRemovedHandler } =
  useModalToggler(chat)

async function submit(payload: ChatPayload) {
  await updateChat(chat.value!.id, payload)

  notify({
    title: t('success.update.title'),
    message: t('success.update.message'),
  })
  toggleModal(false)
}

onModalClose(() => emit('close'))
</script>

<template>
  <Modal
    :is-active="isModalActive"
    :title="t('modal.title')"
    @close="toggleModal(false)"
    @removed="modalRemovedHandler"
  >
    <ChatForm
      :action-status="$actions.updateChat"
      :chat-data="chat || {}"
      @cancel-click="toggleModal(false)"
      @submit="submit"
    />
  </Modal>
</template>

/* v8 ignore start */
<i18n locale="en">
modal:
  title: Edit chat
success:
  update:
    title: Success
    message: Chat has been updated
</i18n>

<i18n locale="de">
modal:
  title: Chat bearbeiten
success:
  update:
    title: Erfolgreich
    message: Chat wurde aktualisiert
</i18n>
/* v8 ignore stop */
