import type { Join, SetRequired } from 'type-fest'
import api from './api'
import type {
  PathsToStringProps,
  ViewCreatePermission,
  ViewEditPermission,
} from './types'
import type { User } from './user'
import type { AuthResponse } from './auth'
import type { JsonApiResponse } from '@papershift/jsonapi/src/types'

export type Account = {
  id: string
  created_at: string
  name: string
  user?: User
  permissions?: AccountPermissions
}

export type AccountPermissions = {
  // these permissions come with account-settings-permissions endpoint
  general: ViewEditPermission
  organization_users: ViewEditPermission & ViewCreatePermission
  organization_teams: ViewCreatePermission
  organization_roles: ViewCreatePermission
  fields: ViewCreatePermission
  appearance: ViewEditPermission
  onboarding_stages: ViewCreatePermission
  onboarding_templates: ViewCreatePermission
  offboarding_stages: ViewCreatePermission
  offboarding_templates: ViewCreatePermission
  billing: ViewEditPermission
  workflow: ViewCreatePermission & ViewEditPermission
  agent: ViewEditPermission
  legal: ViewEditPermission
  contract_templates: ViewCreatePermission & ViewEditPermission
}

export type AccountPermissionPaths = Join<
  PathsToStringProps<Required<Account['permissions']>>,
  '.'
>

export type AddAccountParams = {
  name: string
  terms: {
    accepted: boolean
  }
}
export type UpdateAccountParams = SetRequired<Partial<Account>, 'id'>

export function createAccount(params: AddAccountParams): Promise<AuthResponse> {
  return api
    .url('/api/v1/accounts')
    .post({
      data: {
        type: 'account',
        attributes: params,
      },
    })
    .json()
}

export function updateAccount(params: UpdateAccountParams) {
  return api
    .url(`/api/v1/accounts/${params.id}`)
    .patch({
      data: {
        type: 'account',
        attributes: {
          name: params.name,
        },
      },
    })
    .json()
}

export function getAccount(
  accountId: string
): Promise<JsonApiResponse<Account>> {
  return api.url(`/api/v1/accounts/${accountId}`).get().json()
}

export function switchAccounts(
  accountId: string
): Promise<JsonApiResponse<Account>> {
  return api
    .url('/api/v1/switch_accounts')
    .post({
      data: {
        type: 'account',
        id: accountId,
      },
    })
    .json()
}

export function listAccountPermissions(): Promise<
  JsonApiResponse<AccountPermissions>
> {
  return api.url('/ui/v1/account-settings-permissions').get().json()
}
