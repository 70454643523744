import { computed, type Ref } from 'vue'
import type { Actor } from '@papershift/api/src/types'

export default function useAvatar(actor: Ref<Actor | null | undefined>) {
  const avatarUrl = computed(() => {
    if (!actor.value?.avatar) return null

    return `${import.meta.env.VITE_S3_URL}/${actor.value.id}/${actor.value.avatar}`
  })

  const initials = computed(() => {
    if (!actor.value || actor.value.name.length === 0) return null

    return actor.value.name
      .split(' ')
      .slice(0, 2)
      .map((namePart) => namePart[0])
      .join('')
  })

  return { avatarUrl, initials }
}
