<script setup lang="ts">
import { useI18n } from '@papershift/locale/src/i18n'
import { confirm } from '@papershift/ui/src/Confirm'
import type { Chat } from '@papershift/api/src/chat'
import type { Option } from '@papershift/ui/src/Dropdown.vue'
import useChatActions, { type Action } from '../composables/use-chat-actions'
import useChatStore from '@/stores/chat/chat.store'
import { EllipsisVerticalIcon, XMarkIcon } from '@papershift/ui/src/icons'

const props = defineProps<{
  chat: Chat
}>()

const emit = defineEmits<{
  'edit-triggered': []
  'close-triggered': []
}>()

const { t } = useI18n()
const { getActions, handleAction } = useChatActions()
const chatStore = useChatStore()

async function handleSelectOption(option: Option<Action>) {
  if (option.value === 'edit') {
    emit('edit-triggered')
  } else {
    const confirmed = await confirm(
      t(`confirm.${option.value}.message`),
      t(`confirm.${option.value}.button_label`),
      t(`confirm.${option.value}.title`)
    )

    if (confirmed) {
      await handleAction(props.chat, option)
      emit('close-triggered')
      await chatStore.fetchChats()
    }
  }
}
</script>

<template>
  <div class="px-4 sm:px-6">
    <div class="flex items-start justify-between">
      <h3 class="text-base font-semibold leading-6 text-gray-900">
        {{ chat.subject }}
      </h3>

      <div class="ml-3 flex h-7 items-start">
        <Dropdown
          v-if="getActions(chat).length > 0"
          :id="`chat-view-actions-${chat.id}`"
          :options="getActions(chat)"
          class="actions-dropdown mr-3"
          @option-select="handleSelectOption($event)"
        >
          <template #trigger>
            <EllipsisVerticalIcon class="size-6" aria-hidden="true" />
          </template>
        </Dropdown>

        <button
          type="button"
          class="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-slate-600 focus:ring-offset-2"
          @click="emit('close-triggered')"
        >
          <span class="absolute -inset-2.5" />
          <span class="sr-only">Close panel</span>
          <XMarkIcon class="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.actions-dropdown :deep(button) {
  @apply ring-0 px-1 py-0;
}
</style>

/* v8 ignore start */
<i18n locale="en">
confirm:
  close:
    title: Close Chat
    message: Are you sure you want to close this chat?
    button_label: Close
  open:
    title: Reopen Chat
    message: Are you sure you want to reopen this chat?
    button_label: Open
</i18n>

<i18n locale="de">
confirm:
  close:
    title: Chat schließen
    message: Möchtest du diesen Chat wirklich schließen?
    button_label: Schließen
  open:
    title: Chat wieder öffnen
    message: Möchtest du diesen Chat wirklich wieder öffnen?
    button_label: Öffnen
</i18n>
/* v8 ignore stop */
