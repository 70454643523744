<script setup lang="ts">
import * as yup from 'yup'
import { ref } from 'vue'
import { useI18n } from '@papershift/locale/src/i18n'
import type { ChatPayload } from '@papershift/api/src/chat'
import type { Status } from '@papershift/action-status/src/types'

const props = defineProps<{
  actionStatus: Status
  chatData: Partial<ChatPayload>
}>()

defineEmits<{
  'cancel-click': []
  submit: [payload: ChatPayload]
}>()

const { t } = useI18n()

const payload = ref<ChatPayload>({
  subject: props.chatData.subject || '',
})

const validation = {
  schema: {
    chat_subject: yup.string().required(),
  },
}
</script>

<template>
  <Form
    :action-status="actionStatus"
    :submit-handler="() => $emit('submit', payload)"
    :validation="validation"
  >
    <Input
      id="chat_subject"
      class="mt-4"
      :tabindex="1"
      :label="t('input.subject')"
      :model-value="chatData?.subject"
      @update:model-value="payload.subject = $event"
    />

    <div class="flex flex-col-reverse justify-between mt-5 sm:mt-4 sm:flex-row">
      <div class="sm:ml-auto sm:flex sm:flex-row-reverse">
        <Button
          id="chat_edit_submit"
          appearance="primary"
          :tabindex="97"
          type="submit"
          :label="t('button.submit')"
          class="w-full sm:ml-3 sm:w-auto"
        />

        <Button
          id="chat_form_cancel"
          appearance="secondary"
          :tabindex="98"
          type="button"
          :label="t('button.cancel')"
          class="mt-3 w-full sm:mt-0 sm:w-auto"
          @click="$emit('cancel-click')"
        />
      </div>
    </div>
  </Form>
</template>

/* v8 ignore start */
<i18n locale="en">
button:
  submit: Submit
  cancel: Cancel
input:
  subject: Subject
</i18n>

<i18n locale="de">
button:
  submit: Speichern
  cancel: Abbrechen
input:
  subject: Betreff
</i18n>
/* v8 ignore stop */
