import type { State, RecordGeneric } from './types'
import { classifyRecords, getRef } from './utils'
import { getRecordIndex, reindexSingle, reindexMultiple } from './indexing'

// deletes multiple records by given matches. Approach to match records is done
// by "OR" logic. This means, if a record matches any object in `matches`, then
// it's deleted. Process will be faster, if matches contain `id`, since it uses
// indexes to find records by ids
export function deleteRecords(
  state: State,
  { type, matches }: { type: string; matches: RecordGeneric[] }
) {
  getRef(state, type)

  let smallestIndex = -1
  const setSmallestIndex = (index: number) => {
    if (smallestIndex === -1 || index < smallestIndex) {
      smallestIndex = index
    }
  }
  const { withId, withoutId } = classifyRecords(matches)

  // quicker iteration for deletion with id matching
  if (withId.length) {
    const lastIndex = deleteIndexedMatches(state, type, withId)
    setSmallestIndex(lastIndex)
  }

  if (withoutId.length) {
    deleteNonIndexedMatches(state, type, withoutId, setSmallestIndex)
  }

  if (smallestIndex > -1) {
    reindexMultiple(state, type, smallestIndex)
  }
}

function deleteIndexedMatches(
  state: State,
  type: string,
  matches: RecordGeneric[]
) {
  const stateRecords = getRef(state, type)
  const indexes = []

  for (const match of matches) {
    const index = getRecordIndex(state, type, match.id)

    if (index > -1) {
      reindexSingle(state, type, match.id, null)
      indexes.push(index)
    }
  }
  indexes.sort((a, b) => b - a)

  for (const index of indexes) {
    stateRecords.value.splice(index, 1)
  }

  return indexes[indexes.length - 1]
}

function deleteNonIndexedMatches(
  state: State,
  type: string,
  matches: RecordGeneric[],
  setSmallestIndex: (index: number) => void
) {
  const stateRecords = getRef(state, type)

  for (let i = stateRecords.value.length - 1; i >= 0; --i) {
    const record = stateRecords.value[i]
    const isMatch = matches.some((match) => {
      return Object.keys(match).every((key) => {
        return record[key] === match[key]
      })
    })
    if (isMatch) {
      reindexSingle(state, type, record.id, null)
      stateRecords.value.splice(i, 1)
      setSmallestIndex(i)
    }
  }
}
