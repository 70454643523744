import { ref } from 'vue'
import { defineStore } from 'pinia'
import type { SetFieldType } from 'type-fest'
import useRoleStore from '../role/role.store'
import * as api from '@papershift/api/src/team'
import type { Role, TeamMembership } from '@papershift/api/src/role'
import {
  listUsersByTeam,
  getUserWithRolesInTeam,
  type User,
  type UserMeta,
} from '@papershift/api/src/user'
import {
  addIncluded,
  clearRecords,
  prependRecords,
  setRecords,
  addRecords,
} from '@papershift/jsonapi/src/records.mutations'
import { clearState } from '../utils'
import {
  extractIncluded,
  restructureRecord,
} from '@papershift/jsonapi/src/utils'
import type {
  JsonApiDataItem,
  JsonApiIncluded,
  JsonApiOffsetPaginationMeta,
} from '@papershift/jsonapi/src/types'
import {
  type FilterItem,
  FilterOperator,
} from '@papershift/api/src/filter-utils'

export type TeamMembershipWithRole = TeamMembership & { role: Role }
export type UserWithRoles = SetFieldType<
  User,
  'team_memberships',
  TeamMembershipWithRole[]
>

const teamStore = defineStore('team', () => {
  const roleStore = useRoleStore()

  const state = {
    records: {
      team: ref<api.Team[]>([]),
      user: ref<UserWithRoles[]>([]),
      team_membership: ref<TeamMembershipWithRole[]>([]),
    },
    meta: {
      team: ref<JsonApiOffsetPaginationMeta | null>(null),
    },
    searchKeyword: ref(''),
  }

  // TODO: convert this to a getter by leveraging profileUser from userStore
  function profileUserTeams(userId?: string) {
    if (!userId) {
      return []
    }

    return state.records.team.value.filter((team) =>
      team.team_memberships?.some((membership) => membership.user_id === userId)
    )
  }

  function $resetState() {
    clearState(state)
  }

  async function fetchTeams(
    search = state.searchKeyword.value,
    page = state.meta.team.value?.current_page
  ) {
    state.searchKeyword.value = search
    const filters: FilterItem[] = []

    if (search) {
      filters.push({
        key: 'name',
        operator: FilterOperator.CT,
        value: search,
      })
    }
    const { data, included = [], meta } = await api.listTeams(filters, page)

    setRecords(state, { type: 'team', records: data })
    addIncluded(state, { records: included })
    state.meta.team.value = meta
  }

  async function fetchTeamsWithTeamMemberships() {
    const { data, included = [] } = await api.listTeamsWithTeamMemberships()
    addIncludedTeamMemberships(included)
    addRecords(state, { type: 'team', records: data })
  }

  async function getTeam(teamId: string) {
    const { data } = await api.getTeam(teamId)
    return restructureRecord(data, state)
  }

  async function createTeam(payload: api.TeamPayload) {
    return api.createTeam(payload)
  }

  function updateTeam(teamId: string, payload: api.TeamPayload) {
    return api.updateTeam(teamId, payload)
  }

  function deleteTeam(teamId: string) {
    return api.deleteTeam(teamId)
  }

  async function fetchTeamUsers(teamId: string) {
    clearRecords(state, { type: 'user' })

    const { data, included = [] } = await listUsersByTeam(teamId)
    setRecords(state, { type: 'user', records: data })
    addIncludedTeamMemberships(included)
  }

  async function getTeamUserRoles(teamId: string, userId: string) {
    const { included = [] } = await getUserWithRolesInTeam(userId, teamId)

    return included.map(
      (membership) => roleStore.rolesObject[membership.attributes.role_id]
    )
  }

  function prependTeamUser(
    user: JsonApiDataItem<User, UserMeta>,
    included: JsonApiIncluded
  ) {
    addIncludedTeamMemberships(included)
    prependRecords(state, { type: 'user', records: [user] })
  }

  async function addIncludedTeamMemberships(includedRaw: JsonApiIncluded) {
    const included = extractIncluded(includedRaw)

    if (included.team_membership) {
      if (!roleStore.roles.length) await roleStore.fetchRoles()

      addRecords(state, {
        type: 'team_membership',
        records: included.team_membership.map((membership) => {
          membership.attributes.role =
            roleStore.rolesObject[membership.attributes.role_id]
          return membership
        }),
      })
    }
  }

  return {
    $resetState,

    teams: state.records.team,
    users: state.records.user,
    teamMeta: state.meta.team,
    searchKeyword: state.searchKeyword,
    profileUserTeams,

    fetchTeams,
    fetchTeamsWithTeamMemberships,
    getTeam,
    createTeam,
    updateTeam,
    deleteTeam,
    fetchTeamUsers,
    getTeamUserRoles,

    prependTeamUser,
  }
})

export default teamStore
