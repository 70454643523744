import type { ChatMessage, MessageReaction } from '@papershift/api/src/chat'
import useChatStore from '@/stores/chat/chat.store'
import useAuthStore from '@/stores/auth/auth.store'
import { ref } from 'vue'
import type { TippyInstance } from 'vue-tippy'
import debounce from 'lodash/debounce'

export type TippyInst = Extract<TippyInstance, { id: number }>

export default function useMessageReactions() {
  const chatStore = useChatStore()
  const authStore = useAuthStore()

  const tippyInstance = ref<TippyInst>()
  const reactionList = ['😀', '👍', '👎', '❤️', '✅', '🙏']

  const onReact = debounce(async (message: ChatMessage, reaction: string) => {
    tippyInstance.value?.hide()

    const payload: Partial<MessageReaction> = {
      reaction,
    }

    const userReaction = getCurrentUsersReaction(message, reaction)

    if (userReaction) {
      await chatStore.deleteMessageReaction(message.id, userReaction.id)
    } else {
      await chatStore.reactToMessage(message.id, payload)
    }
  }, 300)

  const canReactToMessage = () => {
    return chatStore.currentChat?.open
  }

  const getCurrentUsersReaction = (message: ChatMessage, reaction: string) => {
    return message.message_reactions?.find(
      (r) => r.reaction === reaction && r.author_id === authStore.user?.id
    )
  }

  const getMessageReactions = (
    message: ChatMessage
  ): { reaction: string; count: number }[] => {
    const groupedReactions =
      message.message_reactions?.reduce(
        (result: any, item: MessageReaction) => {
          // Extract the reaction key
          const reactionKey = item['reaction']

          if (!result[reactionKey]) {
            // Initialize if it doesn't exist
            result[reactionKey] = []
          }

          // Push the reaction item into the corresponding array
          result[reactionKey].push(item) // Push the reaction item into the corresponding array

          return result
        },
        {}
      ) ?? []

    return Object.keys(groupedReactions).map((key) => {
      return {
        reaction: key,
        count: groupedReactions[key].length,
      }
    })
  }

  const setTippyInstance = (instance: TippyInst) => {
    tippyInstance.value = instance
  }

  async function fetchReactionAuthors(
    tippy: TippyInst,
    messageId: string,
    reaction: string
  ) {
    await chatStore.fetchReactionAuthors(messageId)

    tippy.setContent(
      chatStore.reactionAuthors.get(messageId)?.get(reaction) ?? reaction
    )
  }

  return {
    reactionList,
    onReact,
    canReactToMessage,
    getMessageReactions,
    setTippyInstance,
    getCurrentUsersReaction,
    fetchReactionAuthors,
  }
}
