import useChatStore from '@/stores/chat/chat.store'
import useAuthStore from '@/stores/auth/auth.store'
import { createChannel } from '@/utils/cable'
import { flattenRecord } from '@papershift/jsonapi/src/utils'
import type { ChatContextType } from '@papershift/api/src/chat'
import type { UserChannelEvent } from '@papershift/api/src/realtime'

export default function useUserChannel(contextType?: ChatContextType) {
  const authStore = useAuthStore()
  const chatStore = useChatStore()

  let channel: { unsubscribe: () => void } | null = null

  function subscribe(received: (event: UserChannelEvent) => void) {
    channel?.unsubscribe()
    channel = createChannel(
      { channel: 'UserChannel', user_id: authStore.selectedUserId! },
      { received: received }
    )
  }

  function unsubscribe() {
    channel?.unsubscribe()
    channel = null
  }

  function handleOpenChat(event: UserChannelEvent) {
    if (event.action !== 'createdChat') return
    if (event.chat.data.attributes.context_type !== contextType) return

    chatStore.currentChat = flattenRecord(event.chat.data)
  }

  async function handleListRefetch(event: UserChannelEvent) {
    if (event.action === 'newChatMessage') return

    await chatStore.fetchChats()
  }

  function handleCurrentChatEdit(event: UserChannelEvent) {
    if (event.action !== 'updatedChat') return
    if (event.chat.data.id !== chatStore.currentChat?.id) return

    const chat = flattenRecord(event.chat.data)
    chat.bookmark = chatStore.currentChat.bookmark

    chatStore.currentChat = chat
  }

  return {
    subscribe,
    unsubscribe,

    handleOpenChat,
    handleListRefetch,
    handleCurrentChatEdit,
  }
}
