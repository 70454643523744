import debounce from 'lodash/debounce'
import type { Ref } from 'vue'
import * as chatApi from '@papershift/api/src/chat'
import type * as ChatApi from '@papershift/api/src/chat'
import type { ChatMessageState } from '@/stores/chat/chat.store'
import {
  addRecords,
  deleteRecords,
} from '@papershift/jsonapi/src/records.mutations'
import type { JsonApiDataItem } from '@papershift/jsonapi/src/types'
import { restructureRecord } from '@papershift/jsonapi/src/utils'

type State = {
  reactionAuthors: Ref<Map<string, Map<string, string>>>
  messages: Ref<Map<string, ChatMessageState>>
  records: {
    message_reaction: Ref<ChatApi.MessageReaction[]>
  }
}

export default function useMessageReactions(state: State) {
  function reactToMessage(
    messageId: string,
    payload: Partial<ChatApi.MessageReaction>
  ) {
    state.reactionAuthors.value.clear()
    return chatApi.reactToMessage(messageId, payload)
  }

  const fetchReactionAuthors = debounce(
    async (messageId: string) => {
      if (!state.reactionAuthors.value.has(messageId)) {
        state.reactionAuthors.value.clear() // max cache: no more than 1 message reactions
        state.reactionAuthors.value.set(messageId, new Map())

        const result = await getMessageReactionAuthors(messageId)
        state.reactionAuthors.value.set(messageId, result)
      }
    },
    300,
    { leading: true, trailing: false }
  )

  function deleteMessageReaction(messageId: string, reactionId: string) {
    state.reactionAuthors.value.clear()
    return chatApi.deleteMessageReaction(messageId, reactionId)
  }

  function addReaction(
    reaction: JsonApiDataItem<ChatApi.MessageReaction>,
    message: JsonApiDataItem<ChatApi.ChatMessage>
  ) {
    const chatMessages = state.messages.value.get(message.attributes.chat_id)
    const messageIndex = chatMessages?.messages.findIndex(
      (m) => m.id === message.id
    )

    if (chatMessages && messageIndex !== undefined && messageIndex > -1) {
      addRecords(state, { type: 'message_reaction', records: [reaction] })
      chatMessages.messages[messageIndex] = restructureRecord(message, state)
    }
  }

  function deleteReaction(
    reaction: JsonApiDataItem<ChatApi.MessageReaction>,
    message: JsonApiDataItem<ChatApi.ChatMessage>
  ) {
    const chatMessages = state.messages.value.get(message.attributes.chat_id)
    const messageIndex = chatMessages?.messages.findIndex(
      (m) => m.id === message.id
    )

    if (chatMessages && messageIndex !== undefined && messageIndex > -1) {
      deleteRecords(state, { type: 'message_reaction', matches: [reaction] })
      chatMessages.messages[messageIndex] = restructureRecord(message, state)
    }
  }

  return {
    reactToMessage,
    fetchReactionAuthors,
    deleteMessageReaction,
    addReaction,
    deleteReaction,
  }
}

async function getMessageReactionAuthors(
  messageId: string
): Promise<Map<string, string>> {
  const { data, included = [] } = await chatApi.listMessageReactions(messageId)

  const reactionAuthorsMap = data.reduce(
    (map, { attributes: { reaction, author_id } }) => {
      const author = included.find(({ id }) => author_id === id)

      if (!author) {
        return map
      }

      if (map.has(reaction)) {
        const authorsString = `${map.get(reaction)}, ${author?.attributes.name}`
        map.set(reaction, authorsString)
      } else {
        map.set(reaction, author?.attributes.name)
      }

      return map
    },
    new Map<string, string>()
  )

  return reactionAuthorsMap
}
