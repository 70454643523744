import { ref } from 'vue'
import type { ChatMessage } from '@papershift/api/src/chat'
import { type Option } from '@papershift/ui/src/Dropdown.vue'
import { confirm } from '@papershift/ui/src/Confirm'
import i18n, { addMessages } from '@papershift/locale/src/i18n'
import { PencilSquareOutlineIcon, TrashIcon } from '@papershift/ui/src/icons'
import useAuthStore from '@/stores/auth/auth.store'
import useChatStore from '@/stores/chat/chat.store'

type Action = 'edit' | 'delete'

export default function useChatMessageActions() {
  const { t } = i18n.global
  const chatStore = useChatStore()
  const isEditorActive = ref(false)

  const getDropdownActions = (row: ChatMessage) => {
    const authStore = useAuthStore()
    const actions: Option<Action>[] = []

    if (row.author_id === authStore.user?.id) {
      actions.push(
        {
          label: t('chat_message.actions.edit'),
          value: 'edit',
          icon: PencilSquareOutlineIcon,
        },
        {
          label: t('chat_message.actions.delete.label'),
          value: 'delete',
          icon: TrashIcon,
          theme: 'danger',
        }
      )
    }

    return actions
  }

  async function toggleEditor(open: boolean) {
    isEditorActive.value = open
  }

  async function handleSelectAction(row: ChatMessage, action: Option) {
    if (action.value === 'edit') {
      toggleEditor(true)
    }

    if (action.value === 'delete') {
      const confirmed = await confirm(
        t('chat_message.actions.delete.confirm.message'),
        t('chat_message.actions.delete.confirm.button_label'),
        t('chat_message.actions.delete.confirm.title')
      )
      if (confirmed) {
        await chatStore.deleteChatMessage(row.chat_id, row.id)
      }
    }
  }

  async function editChatMessage(row: ChatMessage, content: string) {
    await chatStore.updateChatMessage(row.chat_id, row.id, content)
    toggleEditor(false)
  }

  return {
    getDropdownActions,
    toggleEditor,
    handleSelectAction,
    editChatMessage,
    isEditorActive,
  }
}

addMessages({
  en: {
    chat_message: {
      actions: {
        edit: 'Edit',
        delete: {
          label: 'Delete',
          confirm: {
            title: 'Delete message',
            message: 'Are you sure you want to delete this message?',
            button_label: 'Delete',
          },
        },
      },
    },
  },
  de: {
    chat_message: {
      actions: {
        edit: 'Bearbeiten',
        delete: {
          label: 'Löschen',
          confirm: {
            title: 'Nachricht löschen',
            message: 'Möchtest du diese Nachricht wirklich löschen?',
            button_label: 'Löschen',
          },
        },
      },
    },
  },
})
