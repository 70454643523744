import { ref, watch, type Ref } from 'vue'
import useAuthStore from '@/stores/auth/auth.store'

export default function useMessageMentionDetector(content: Ref<string>) {
  const authStore = useAuthStore()
  const messageContentElement = ref<HTMLElement | null>(null)
  const isSelfMentioned = ref(false)

  watch(
    [content, messageContentElement],
    () => {
      if (messageContentElement.value) {
        const mentionElem = messageContentElement.value.querySelector(
          `.mention[data-id="${authStore.user!.id}"]`
        )
        isSelfMentioned.value = mentionElem != null
      }
    },
    { immediate: true }
  )

  return {
    messageContentElement,
    isSelfMentioned,
  }
}
