import { computed, nextTick, ref, watch } from 'vue'
import useAuthStore from '@/stores/auth/auth.store'
import useChatStore from '@/stores/chat/chat.store'
import useUserChannel from '@/views/composables/use-user-channel'
import { createChannel } from '@/utils/cable'
import useChatBookmark from '@/views/composables/use-chat-bookmark'
import type {
  Chat,
  ChatMessage,
  MessageReaction,
} from '@papershift/api/src/chat'
import type { JsonApiResponse } from '@papershift/jsonapi/src/types'
import useRoleStore from '@/stores/role/role.store'

type MessageBroadcastedData = {
  action: 'newMessage' | 'editMessage' | 'deleteMessage'
  message: ChatMessage
}

type ReactionBroadcastedData = {
  action: 'newMessageReaction' | 'deleteMessageReaction'
  reaction: JsonApiResponse<MessageReaction>
  message: JsonApiResponse<ChatMessage>
}

type BroadcastedData = MessageBroadcastedData | ReactionBroadcastedData

export default function useChatChannel() {
  const chat = computed<Chat>(() => chatStore.currentChat!)
  const authStore = useAuthStore()
  const chatStore = useChatStore()
  const roleStore = useRoleStore()
  const { deferBookmarkUpdate, triggerBookmarkUpdate, abortBookmarkUpdate } =
    useChatBookmark()
  const {
    subscribe: subscribeToUserChannel,
    unsubscribe: unsubscribeFromUserChannel,
    handleCurrentChatEdit,
  } = useUserChannel()

  let channel: any = null
  const allMessagesRead = ref(true)

  function subscribeChannel() {
    if (!chat.value) return

    channel = createChannel(
      {
        channel: 'ChatChannel',
        chat_id: chat.value.id,
        user_id: authStore.user!.id,
      },
      { received: handleBroadcast }
    )
  }

  function closeChat() {
    channel?.unsubscribe()
    unsubscribeFromUserChannel()
    abortBookmarkUpdate()
    chatStore.closeCurrentChat()
    allMessagesRead.value = true
  }

  async function handleBroadcast(data: BroadcastedData) {
    if ('reaction' in data) {
      await handleReaction(data)
    } else if ('message' in data) {
      await handleMessage(data)
    }
  }

  async function handleMessage(data: MessageBroadcastedData) {
    if (data.action === 'newMessage') {
      await handleNewMessage(data)
    } else if (data.action === 'editMessage') {
      chatStore.editMessage(data.message)
    } else if (data.action === 'deleteMessage') {
      chatStore.deleteMessage(chat.value.id, data.message.id)
    }
  }

  async function handleReaction(data: ReactionBroadcastedData) {
    if (data.action === 'newMessageReaction') {
      chatStore.addReaction(data.reaction.data, data.message.data)
    } else if (data.action === 'deleteMessageReaction') {
      chatStore.deleteReaction(data.reaction.data, data.message.data)
    }
  }

  async function handleNewMessage(data: MessageBroadcastedData) {
    const newMessage = data.message
    chatStore.prependMessage(chat.value.id, newMessage)

    await nextTick()

    deferBookmarkUpdate()

    // if the message is from current user, immediately mark chat as read
    if (newMessage.author?.id === authStore.user!.id) {
      triggerBookmarkUpdate()
      allMessagesRead.value = true
    }
  }

  async function fetchChatMembers() {
    await roleStore.fetchRoles()
    return chatStore.fetchChatMembersWithRoles(chat.value.id)
  }

  watch(
    () => chatStore.currentChat?.id,
    async (currentChatId) => {
      channel?.unsubscribe()

      if (currentChatId) {
        subscribeChannel()
        subscribeToUserChannel(handleCurrentChatEdit)
        fetchChatMembers()
      }
    },
    { immediate: true }
  )

  return {
    allMessagesRead,
    closeChat,
    subscribeChannel,

    handleBroadcast,
  }
}
