<script setup lang="ts">
import { useI18n } from '@papershift/locale/src/i18n'
import useChat from '../composables/use-chat'
import useChatStore from '@/stores/chat/chat.store'
import { Tippy } from 'vue-tippy'
import { PaperAirplaneIcon } from '@papershift/ui/src/icons'
import { computed, onMounted, onUnmounted } from 'vue'

defineProps<{
  messageContent: string
}>()

const emit = defineEmits<{
  submit: []
  'update:message-content': [modelValue: string]
  'editing-start': []
  'editing-end': []
}>()

defineSlots<{
  'action-item-right': any
  footer?: any
}>()

const { t } = useI18n()
const { isEmptyMessage } = useChat()

const chatStore = useChatStore()

const members = computed(() =>
  chatStore.chatMembers.map((user) => ({
    value: user.id,
    label: user.name,
  }))
)

onMounted(() => {
  emit('editing-start')
})

onUnmounted(() => {
  emit('editing-end')
})
</script>

<template>
  <CompactRichEditor
    class="scrollbar-hidden text-sm text-black/70"
    :content="messageContent"
    :placeholder="t('type_message_prompt')"
    :editable="true"
    :mention-suggestions="members"
    @enter-pressed="$emit('submit')"
    @update="$emit('update:message-content', $event)"
  >
    <template #action-item-right>
      <slot name="action-item-right">
        <div class="h-full flex items-center">
          <Tippy placement="top" :content="t('send_message_shortcut')">
            <button
              type="button"
              class="size-7 rounded-full flex items-center justify-center bg-indigo-300 hover:bg-indigo-400 disabled:bg-gray-100 text-white disabled:text-gray-400 focus:outline-none disabled:cursor-not-allowed"
              :disabled="isEmptyMessage(messageContent)"
              :aria-label="t('send_message')"
              @click="$emit('submit')"
            >
              <PaperAirplaneIcon class="w-4 h-4" />
            </button>
          </Tippy>
        </div>
      </slot>
    </template>

    <template #footer>
      <slot name="footer" />
    </template>
  </CompactRichEditor>
</template>

/* v8 ignore start */
<i18n locale="en">
send_message: Send message
send_message_shortcut: Enter sends too
type_message_prompt: Type a message
</i18n>

<i18n locale="de">
send_message: Sende Nachricht
send_message_shortcut: Enter sendet auch ab
type_message_prompt: Schreibe eine Nachricht
</i18n>
/* v8 ignore stop */
