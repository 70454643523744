export function getNotImplemented() {
  console.error('not implemented')

  return Promise.resolve({
    data: {
      id: '',
      type: '',
      attributes: {} as any,
      relationships: {},
      meta: {},
    },
    meta: { request_id: '' },
  })
}

export function listNotImplemented() {
  console.error('not implemented')

  return Promise.resolve({
    data: [],
    included: [],
    meta: {
      current_page: 1,
      page_items: 0,
      request_id: '',
      total_count: 0,
      total_pages: 1,
    },
    links: {
      first: '',
      last: '',
      next: null,
      prev: null,
    },
  })
}
