import mergeWith from 'lodash/mergeWith'
import { onMounted, watch, type Ref } from 'vue'
import { useEditor, type EditorOptions } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import TextAlign from '@tiptap/extension-text-align'
import Placeholder from '@tiptap/extension-placeholder'
import TaskList from '@tiptap/extension-task-list'
import TaskItem from '@tiptap/extension-task-item'
import Link from '@tiptap/extension-link'
import useRichEditorLinks from './use-rich-editor-links'

type Data = {
  content: Ref<string | undefined>
  editable: Ref<boolean | undefined>
  placeholder: Ref<string>
  cssClass: string
}

export default function useRichEditor(
  data: Data,
  editorOptions: Partial<EditorOptions>
) {
  const { renderHTML } = useRichEditorLinks()

  const defaultOptions: Partial<EditorOptions> = {
    extensions: [
      StarterKit,
      Placeholder.configure({
        placeholder: data.placeholder.value,
      }),
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      TaskList,
      TaskItem.configure({
        nested: true,
      }),
      Link.extend({ renderHTML }),
    ],
    editorProps: {
      attributes: {
        class:
          'prose prose-sm max-w-full focus:outline-none rounded-md ' +
          data.cssClass,
      },
    },
  }

  const editor = useEditor(
    mergeWith(defaultOptions, editorOptions, (objValue, srcValue) => {
      if (Array.isArray(objValue)) {
        return objValue.concat(srcValue)
      }
    })
  )

  onMounted(() => {
    watch(
      () => data.content.value,
      (content) => {
        if (editor.value.getHTML() !== content) {
          editor.value.commands.setContent(content, false)
          editor.value.options.editable = data.editable.value
        }
      },
      { immediate: true }
    )
  })

  return { editor }
}
