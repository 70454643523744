<script setup lang="ts">
import { toRef, ref, computed, watch } from 'vue'
import { useI18n } from '@papershift/locale/src/i18n'
import type { Option } from '@papershift/ui/src/Select.vue'
import useActionTracker from '@papershift/action-status/src/action-tracker'
import useModalToggler from '@papershift/ui/src/utils/use-modal-toggler'
import useChatStore from '@/stores/chat/chat.store'
import useRoleStore from '@/stores/role/role.store'
import { notify } from '@papershift/ui/src/Notifier'
import { confirm } from '@papershift/ui/src/Confirm'

const { t } = useI18n()

const props = defineProps<{
  chatId: string
  userId: string
  canDelete: boolean
}>()

const emit = defineEmits<{
  close: []
  updated: []
  removed: []
}>()

const $actions = useActionTracker({
  editChatMembership: 'editChatMembership',
})

const roleStore = useRoleStore()
const chatStore = useChatStore()

const roles = computed(() =>
  roleStore.chatRoles.map((role) => ({
    label: role.name,
    value: role.id,
  }))
)
const selectedRoles = ref<Option[]>([])
const { isModalActive, toggleModal, modalRemovedHandler, onModalClose } =
  useModalToggler(toRef(props, 'userId'))

onModalClose(() => {
  selectedRoles.value = []
  emit('close')
})

async function submit() {
  await roleStore.createChatMembership(
    props.chatId,
    props.userId!,
    selectedRoles.value.map((role) => role.value) as [string, ...string[]]
  )

  notify({
    title: t('success.edit.title'),
    message: t('success.edit.message'),
  })
  toggleModal(false)
  emit('updated')
}

async function remove() {
  await roleStore.removeChatMemberships(props.chatId, props.userId!)
  notify({
    title: t('success.remove.title'),
    message: t('success.remove.message'),
  })
  emit('removed')
  toggleModal(false)
}

watch(
  () => isModalActive.value,
  async (isActive) => {
    if (isActive) {
      const user = chatStore.chatMembers.find(
        (member) => member.id === props.userId
      )

      selectedRoles.value = user!.roles!.map((r) => ({
        label: r.name,
        value: r.id,
      }))
    }
  }
)

async function handleDelete() {
  const confirmed = await confirm(
    t('confirm.message'),
    t('confirm.label'),
    t('confirm.title')
  )
  if (confirmed) {
    await remove()
  } else {
    toggleModal(true)
  }
}
</script>

<template>
  <Modal
    :is-active="isModalActive"
    :title="t('edit_modal.title')"
    @close="toggleModal(false)"
    @removed="modalRemovedHandler"
    @delete-click="true"
  >
    <Form :submit-handler="submit" :action-status="$actions.editChatMembership">
      <Select
        id="chat_membership_role_select"
        v-model="selectedRoles"
        :label="t('edit_modal.roles')"
        :multiple="true"
        :options="roles"
        :tabindex="2"
        :placeholder="t('edit_modal.select_roles')"
        class="mt-4"
      />

      <div
        class="flex flex-col-reverse justify-between mt-5 sm:mt-4 sm:flex-row"
      >
        <div class="mt-3 sm:mt-0 text-center">
          <button
            type="button"
            :disabled="!props.canDelete"
            :tabindex="999"
            class="h-full text-sm text-rose-600 hover:text-rose-700 disabled:cursor-not-allowed disabled:text-gray-400"
            @click="handleDelete()"
          >
            {{ t('button.remove') }}
          </button>
        </div>

        <div class="sm:ml-auto sm:flex sm:flex-row-reverse">
          <Button
            id="chat_membership_edit_submit"
            appearance="primary"
            :tabindex="997"
            type="submit"
            :label="t('button.submit')"
            class="w-full sm:ml-3 sm:w-auto"
          />

          <Button
            id="chat_membership_edit_cancel"
            appearance="secondary"
            :tabindex="998"
            type="button"
            :label="t('button.cancel')"
            class="mt-3 w-full sm:mt-0 sm:w-auto"
            @click="toggleModal(false)"
          />
        </div>
      </div>
    </Form>
  </Modal>
</template>

/* v8 ignore start */
<i18n locale="en">
success: 
  edit: 
    title: Success
    message: Chat Member roles successfully updated
  remove: 
    title: Success
    message: Chat Member successfully deleted
edit_modal: 
  title: Update Chat Member
  roles: Roles
  select_roles: Select roles
confirm: 
  title: Remove Chat Member
  message: Are you sure you want to remove this participant from the chat?
  label: Remove
button: 
  submit: Submit
  cancel: Cancel
  remove: Remove Chat Member
</i18n>

<i18n locale="de">
success: 
  edit: 
    title: Erfolgreich
    message: Chat Mitglied Rollen erfolgreich aktualisiert
  remove: 
    title: Erfolgreich
    message: Chat Mitglied erfolgreich gelöscht
edit_modal: 
  title: Chat Mitglied bearbeiten
  roles: Rollen
  select_roles: Rollen auswählen
confirm: 
  title: Chat Mitglied entfernen
  message:
    Möchtest du diesen Teilnehmer wirklich aus dem Chat entfernen?
  label: Entfernen
button: 
  submit: Speichern
  cancel: Abbrechen
  remove: Chat Mitglied entfernen
</i18n>
/* v8 ignore stop */
