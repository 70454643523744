import { ref } from 'vue'
import { defineStore } from 'pinia'
import type { SetFieldType } from 'type-fest'
import useAuthStore from '../auth/auth.store'
import useRoleStore from '../role/role.store'
import * as userApi from '@papershift/api/src/user'
import * as olympusUserApi from '@papershift/api/src/olympus-user'
import type * as UserApi from '@papershift/api/src/user'
import {
  setRecords,
  addRecords,
  replaceRecord,
  addIncluded,
} from '@papershift/jsonapi/src/records.mutations'
import {
  flattenRecord,
  extractIncluded,
  restructureRecord,
} from '@papershift/jsonapi/src/utils'
import { clearState } from '../utils'
import type { Role, AccountMembership } from '@papershift/api/src/role'
import {
  type FilterItem,
  FilterOperator,
} from '@papershift/api/src/filter-utils'
import type {
  JsonApiIncluded,
  JsonApiOffsetPaginationMeta,
} from '@papershift/jsonapi/src/types'
import router from '@/routes'
import type { Account } from '@papershift/api/src/account'

export type AccountMembershipWithRole = AccountMembership & { role: Role }
export type UserWithAccountRoles = SetFieldType<
  UserApi.User,
  'account_memberships',
  AccountMembershipWithRole[]
>

const userStoreFactory =
  (api: typeof userApi | typeof olympusUserApi) => () => {
    const authStore = useAuthStore()
    const roleStore = useRoleStore()

    const state = {
      records: {
        user: ref<UserApi.User[]>([]),
        account: ref<Account[]>([]),
        account_membership: ref<AccountMembershipWithRole[]>([]),
      },
      meta: {
        user: ref<JsonApiOffsetPaginationMeta | null>(null),
      },
      profileUser: ref<UserApi.ProfileUser | null>(null),
      searchKeyword: ref(''),
    }

    function $resetState() {
      clearState(state)
    }

    async function createUser(payload: Partial<UserApi.User>) {
      const { data } = await api.createUser(payload)

      return flattenRecord(data)
    }

    async function updateUser(payload: Partial<UserApi.User>) {
      if (typeof payload.id !== 'undefined') {
        const params: UserApi.UpdateUserParams = {
          id: payload.id,
          ...payload,
        }
        await api.updateUser(params)
      }
    }

    async function fetchUsers(
      search = state.searchKeyword.value,
      page = state.meta.user.value?.current_page
    ) {
      state.searchKeyword.value = search

      const filters: FilterItem[] = []
      if (search) {
        filters.push({
          key: 'name',
          operator: FilterOperator.CT,
          value: search,
        })
      }

      const { data, meta } = await api.listUsers(filters, page)

      setRecords(state, { type: 'user', records: data })
      state.meta.user.value = meta
    }

    async function fetchAccountUsers(
      search = state.searchKeyword.value,
      page = state.meta.user.value?.current_page
    ) {
      state.searchKeyword.value = search

      const filters: FilterItem[] = []
      if (search) {
        filters.push({
          key: 'name',
          operator: FilterOperator.CT,
          value: search,
        })
      }

      const {
        data,
        meta,
        included = [],
      } = await api.listUsersWithAccountRoles(filters, page)

      await addIncludedAccountMemberships(included)
      setRecords(state, { type: 'user', records: data })
      state.meta.user.value = meta
    }

    async function getAvailableUsers(filters: FilterItem[]) {
      const { data, included = [] } = await api.listAvailableUsers(filters)

      addIncluded(state, { records: included })

      return data.map((userDataItem) => restructureRecord(userDataItem, state))
    }

    async function getUser(userId: string) {
      const { data } = await api.getUser(userId)
      return flattenRecord(data)
    }

    async function getAccountUserRoles(userId: string) {
      const { included = [] } = await api.getUserWithRolesInAccount(userId)

      return included.map(
        (membership) => roleStore.rolesObject[membership.attributes.role_id]
      )
    }

    async function deleteUser(userId: string) {
      await api.deleteUser(userId)
    }

    async function uploadAvatar(
      userId: string,
      payload: { file: Blob; extension: string; mime: string }
    ) {
      const avatarFilename = `avatar/${crypto.randomUUID()}.${payload.extension}`
      const { data } = await api.getUploadUrl(userId, avatarFilename)

      if (!data.attributes.upload_url) {
        throw new Error('Error generating upload URL.')
      }

      try {
        await api.uploadFile({ url: data.attributes.upload_url, ...payload })
      } catch {
        throw new Error('Could not upload user avatar.')
      }

      await api.updateUser({
        id: userId,
        avatar: avatarFilename,
      })
    }

    async function inviteUser(userId: string) {
      const { data } = await api.createInvite(userId)
      replaceRecord(state, {
        type: 'user',
        match: { id: data.id },
        replacement: data,
      })
    }

    async function revokeInvite(userId: string) {
      const { data } = await api.deleteInvite(userId)
      replaceRecord(state, {
        type: 'user',
        match: { id: data.id },
        replacement: data,
      })
    }

    async function fetchProfileUser(userId: string) {
      const currentUserId = authStore.user?.id
      let user

      if (userId === currentUserId) {
        user = authStore.user!
      } else {
        user = getUser(userId)
      }

      const [profileUser, { data: profilePermissions }] = await Promise.all([
        user,
        api.listProfilePermissions(userId),
      ])

      state.profileUser.value = {
        ...profileUser,
        profilePermissions: profilePermissions.attributes,
      }
    }

    async function addIncludedAccountMemberships(includedRaw: JsonApiIncluded) {
      const included = extractIncluded(includedRaw)

      if (included.account_membership) {
        if (!roleStore.roles.length) await roleStore.fetchRoles()

        addRecords(state, {
          type: 'account_membership',
          records: included.account_membership.map((membership) => {
            membership.attributes.role =
              roleStore.rolesObject[membership.attributes.role_id]
            return membership
          }),
        })
      }
    }

    return {
      $resetState,

      users: state.records.user,
      usersMeta: state.meta.user,
      searchKeyword: state.searchKeyword,
      profileUser: state.profileUser,

      createUser,
      updateUser,
      fetchUsers,
      fetchAccountUsers,
      getAvailableUsers,
      getUser,
      getAccountUserRoles,
      deleteUser,
      fetchProfileUser,
      uploadAvatar,

      inviteUser,
      revokeInvite,

      state,
    }
  }

const userStore = defineStore('user', userStoreFactory(userApi))
const olympusUserStore = defineStore(
  'olympusUser',
  userStoreFactory(olympusUserApi)
)

export default function useUserStore() {
  const routeName = router.currentRoute.value.name as string

  if (routeName?.startsWith('olympus')) {
    return olympusUserStore()
  }

  return userStore()
}
