<script setup lang="ts">
import { Tippy } from 'vue-tippy'
import { FaceSmileIcon } from '@papershift/ui/src/icons'
import useMessageReactions from '@/components/composables/use-message-reactions'
import type { ChatMessage } from '@papershift/api/src/chat'

defineProps<{
  message: ChatMessage
}>()

const {
  reactionList,
  onReact,
  canReactToMessage,
  setTippyInstance,
  getCurrentUsersReaction,
} = useMessageReactions()
</script>

<template>
  <Tippy
    v-if="canReactToMessage"
    id="message-reaction-trigger"
    placement="bottom"
    class="h-fit"
    :allow-h-t-m-l="true"
    :interactive="true"
    trigger="click"
    @trigger="setTippyInstance($event)"
  >
    <template #content>
      <button
        v-for="(reaction, index) in reactionList"
        :key="`reaction-${index}`"
        class="mr-2 last:mr-0 rounded py-1 px-2"
        :class="{
          'bg-slate-600': getCurrentUsersReaction(message, reaction),
        }"
        @click="onReact(message, reaction)"
      >
        {{ reaction }}
      </button>
    </template>

    <FaceSmileIcon
      class="cursor-pointer h-5 w-5 mt-0 pt-0"
      aria-hidden="true"
    />
  </Tippy>
</template>
