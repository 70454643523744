import { watch } from 'vue'
import { createChannel } from '@/utils/cable'
import router from '@/routes'
import useAuthStore from '@/stores/auth/auth.store'
import { useWindowFocus } from '@vueuse/core'
import { type UserChannelEvent } from '@papershift/api/src/realtime'

export default function useChatWorker() {
  if (!navigator.setAppBadge) {
    console.warn('Badging API is not supported')
    return
  }

  let channel: any = null
  const isWindowFocused = useWindowFocus()
  const authStore = useAuthStore()

  let currentSubscriberId: string | undefined

  watch(
    [
      () => authStore.user?.id,
      () => router.currentRoute.value.name as string,
      () => isWindowFocused.value,
    ],
    ([subscriberId, routeName]) => {
      if (channel && subscriberId !== currentSubscriberId) {
        cleanup()
        return
      }
      if (isWindowFocused.value && routeName?.startsWith('chat')) {
        cleanup()
        return
      }

      if (!subscriberId) return

      subscribe(subscriberId)
      currentSubscriberId = subscriberId
    },
    { immediate: true }
  )

  function subscribe(subscriberId: string) {
    channel = createChannel(
      {
        channel: 'UserChannel',
        user_id: subscriberId,
      },
      { received: eventHandler }
    )
  }

  function cleanup() {
    navigator.clearAppBadge()

    if (channel) {
      channel.unsubscribe()
      channel = null
    }
  }

  function eventHandler(event: UserChannelEvent) {
    if (event.action === 'newChatMessage') {
      navigator.setAppBadge()
    }
  }
}
