import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import useTeamStore from '../team/team.store'
import * as api from '@papershift/api/src/role'
import { setRecords } from '@papershift/jsonapi/src/records.mutations'
import { clearState } from '../utils'
import { flattenRecord } from '@papershift/jsonapi/src/utils'
import useRoleTemplates from './composables/use-role-templates'
import type { JsonApiOffsetPaginationMeta } from '@papershift/jsonapi/src/types'
import type { RolePayload } from '@papershift/api/src/role'
import i18n, { addMessages } from '@papershift/locale/src/i18n'
import {
  type FilterItem,
  FilterOperator,
} from '@papershift/api/src/filter-utils'

const roleStore = defineStore('role', () => {
  const { t } = i18n.global

  const state = {
    records: {
      role: ref<api.Role[]>([]),
    },
    meta: {
      role: ref<JsonApiOffsetPaginationMeta | null>(null),
    },
  }

  const { roleTemplatesCompact, getRoleTemplatePayload, fetchRoleTemplates } =
    useRoleTemplates()

  const rolesObject = computed<Record<string, api.Role>>(() =>
    state.records.role.value.reduce((acc: Record<string, api.Role>, role) => {
      acc[role.id] = role
      return acc
    }, {})
  )

  const teamRoles = computed<api.Role[]>(() =>
    state.records.role.value.filter((role) => role.scope === 'team')
  )

  const accountRoles = computed<api.Role[]>(() =>
    state.records.role.value.filter((role) => role.scope === 'account')
  )

  const chatRoles = computed<api.Role[]>(() =>
    state.records.role.value.filter((role) => role.scope === 'chat')
  )

  function $resetState() {
    clearState(state)
  }

  async function fetchRoles(
    search = '',
    page = state.meta.role.value?.current_page
  ) {
    const filters: FilterItem[] = []

    if (search) {
      filters.push({
        key: 'name',
        operator: FilterOperator.CT,
        value: search,
      })
    }

    const { data, meta } = await api.listRoles(filters, page)

    setRecords(state, { type: 'role', records: data })
    state.meta.role.value = meta
  }

  async function createRole(payload: api.RolePayload) {
    return api.createRole(payload)
  }

  async function createRolesFromTemplates(templateIds: string[]) {
    const requests = templateIds.map((id) => {
      const payload = getRoleTemplatePayload(id)

      if (payload) {
        return createRole(payload)
      } else {
        console.error('Could not find template with ID', id)
      }
    })

    await Promise.all(requests)
  }

  async function getRole(roleId: string) {
    const { data } = await api.getRole(roleId)
    return flattenRecord(data)
  }

  function updateRole(roleId: string, payload: Partial<api.RolePayload>) {
    return api.updateRole(roleId, payload)
  }

  async function duplicateRole(roleId: string) {
    const { data: duplicatingRole } = await api.getRole(roleId)

    const payload: RolePayload = {
      ...duplicatingRole.attributes,
      name: t('settings_roles.duplicated_role_name', [
        duplicatingRole.attributes.name,
      ]),
    }

    const { data: addedRole } = await api.createRole(payload)

    return api.updateRole(addedRole.id, {
      rights: {
        ...addedRole.attributes.rights,
        ...duplicatingRole.attributes.rights,
      },
    })
  }

  function deleteRole(roleId: string) {
    return api.deleteRole(roleId)
  }

  async function createTeamMembership(
    teamId: string,
    userId: string,
    roleIds: [string, ...string[]]
  ) {
    const teamStore = useTeamStore()
    const { data, included = [] } = await api.editTeamMembership(
      teamId,
      userId,
      roleIds
    )
    teamStore.prependTeamUser(data, included)
  }

  async function removeTeamMemberships(teamId: string, userId: string) {
    return api.editTeamMembership(teamId, userId, [])
  }

  async function createAccountMembership(
    userId: string,
    roleIds: [string, ...string[]]
  ) {
    await api.createAccountMembership(userId, roleIds)
  }

  async function createChatMembership(
    chatId: string,
    userId: string,
    roleIds: [string, ...string[]]
  ) {
    await api.editChatMembership(chatId, userId, roleIds)
  }

  async function removeChatMemberships(chatId: string, userId: string) {
    await api.editChatMembership(chatId, userId, [])
  }

  return {
    $resetState,

    roles: state.records.role,
    roleMeta: state.meta.role,

    teamRoles,
    accountRoles,
    chatRoles,
    rolesObject,

    fetchRoles,
    createRole,
    getRole,
    updateRole,
    duplicateRole,
    deleteRole,

    roleTemplatesCompact,
    createRolesFromTemplates,
    fetchRoleTemplates,

    createTeamMembership,
    removeTeamMemberships,

    createAccountMembership,

    createChatMembership,
    removeChatMemberships,
  }
})

addMessages({
  en: {
    settings_roles: {
      duplicated_role_name: '{0} (duplicated)',
    },
  },
  de: {
    settings_roles: {
      duplicated_role_name: '{0} (dupliziert)',
    },
  },
})

export default roleStore
