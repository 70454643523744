<script setup lang="ts">
import { computed, ref } from 'vue'
import { Tippy } from 'vue-tippy'
import { format } from 'date-fns'
import useAuthStore from '@/stores/auth/auth.store'
import Avatar from '@/components/Avatar.vue'
import useChatMessageActions from '@/components/composables/use-chat-message-actions'
import { EllipsisVerticalIcon } from '@papershift/ui/src/icons'
import { useI18n } from '@papershift/locale/src/i18n'
import useDisplaySettingsStore from '@/stores/display-settings/display-settings.store'
import EditorFooter from '@papershift/ui/src/editor/EditorFooter.vue'
import useMessageMentionDetector from './composables/use-message-mention-detector'
import ChatMessageEditor from './ChatMessageEditor.vue'
import useMessageReactions, {
  type TippyInst,
} from '@/components/composables/use-message-reactions'
import type { ChatMessage } from '@papershift/api/src/chat'
import ChatMessageReactions from '@/components/ChatMessageReactions.vue'

const { t } = useI18n()
const isEditing = ref(false)

const props = defineProps<{
  message: ChatMessage
  isAvatarShown: boolean
  isChatOpen: boolean
}>()

const content = ref(props.message.content)
const {
  getDropdownActions,
  handleSelectAction,
  editChatMessage,
  isEditorActive,
} = useChatMessageActions()

const { getMessageReactions, fetchReactionAuthors } = useMessageReactions()

const authStore = useAuthStore()
const displaySettingsStore = useDisplaySettingsStore()
const language = computed(() => displaySettingsStore.settings.language)

const { messageContentElement, isSelfMentioned } =
  useMessageMentionDetector(content)
const isSelf = computed(() => props.message.author_id === authStore.user!.id)
const createdAt = computed(() =>
  format(new Date(props.message.created_at), 'HH:mm')
)
</script>

<template>
  <div class="chat-message xs:my-3 md:my-2.5 flex flex-wrap">
    <div class="flex-shrink-0" :class="{ 'ml-9': !isAvatarShown }">
      <Tippy placement="left-start" :content="message.author?.name">
        <Avatar v-if="isAvatarShown" :actor="message.author" class="w-9 h-9" />
      </Tippy>
    </div>

    <ChatMessageEditor
      v-if="isEditorActive"
      :message-content="message.content"
      :editable="true"
      :sticky-header="false"
      class="chat-message-editor ml-3 flex-1 border border-t-0 w-3/4 break-words hyphens-auto"
      @enter-pressed="editChatMessage(message, content)"
      @update:message-content="content = $event"
      @editing-start="isEditing = true"
      @editing-end="isEditing = false"
    >
      <template #action-item-right><span /></template>
      <template #footer>
        <EditorFooter
          :id="`chat-message-${message.id}`"
          @save="editChatMessage(message, content)"
          @cancel="isEditorActive = false"
        />
      </template>
    </ChatMessageEditor>

    <div v-else class="mr-1 flex-1">
      <!-- Actual message bubble -->
      <div
        class="inline-block group p-3 rounded-b transition duration-500 rounded-tr ml-3"
        :class="
          isSelfMentioned
            ? 'bg-yellow-50'
            : isSelf
              ? 'bg-indigo-50'
              : 'bg-gray-50'
        "
      >
        <!-- eslint-disable vue/no-v-html -->
        <p
          ref="messageContentElement"
          class="chat-message-content prose prose-sm outline-none text-gray-700 text-sm font-normal leading-5 hyphens-auto break-words"
          :lang="language"
          v-html="message.content"
        />
        <span
          v-if="message.created_at !== message.updated_at"
          class="text-xs font-light opacity-60"
        >
          ({{ t('chat_message.edited') }})
        </span>

        <div class="flex justify-end w-full">
          <p
            class="outline-none text-[10px] text-black opacity-60 font-light whitespace-pre"
          >
            {{ createdAt }}
          </p>
        </div>
      </div>
    </div>

    <Dropdown
      v-if="!isEditorActive && isChatOpen && getDropdownActions(message).length"
      :id="`chat-message-${message.id}-actions`"
      class="ml-1"
      :borderless="true"
      :options="getDropdownActions(message)"
      @option-select="handleSelectAction(message, $event)"
    >
      <template #trigger>
        <EllipsisVerticalIcon class="h-5 w-5" aria-hidden="true" />
      </template>
    </Dropdown>

    <ChatMessageReactions v-if="!isEditing" class="ml-1" :message="message" />

    <div class="ml-12 basis-full flex flex-wrap gap-1">
      <Tippy
        v-for="reaction in getMessageReactions(message)"
        :key="reaction.reaction"
        :on-show="
          (instance: TippyInst) =>
            fetchReactionAuthors(
              instance,
              message.id,
              reaction.reaction
            ) as unknown as void
        "
        content="..."
        class="flex items-center rounded px-2 py-1 mt-1 bg-gray-50"
      >
        <div class="text-sm mr-1">{{ reaction.reaction }}</div>
        <div class="text-[10px] font-medium text-gray-500">
          {{ reaction.count }}
        </div>
      </Tippy>
    </div>
  </div>
</template>

/* v8 ignore start */
<i18n locale="en">
chat_message:
  edited: edited
</i18n>

<i18n locale="de">
chat_message:
  edited: bearbeitet
</i18n>
/* v8 ignore stop */

<style scoped>
:deep(.chat-message-content) a {
  @apply text-pink-600 no-underline;
}

:deep(.chat-message-content ul[data-type='taskList'] li) {
  @apply flex gap-2;
}

:deep(.chat-message-content ul[data-type='taskList'] li label) {
  @apply p-0;
}

:deep(.chat-message-content li p) {
  @apply m-0;
}

:deep(.chat-message-content) .mention {
  @apply text-pink-600;
}

:deep(.chat-message-content) {
  overflow-wrap: break-word;
  word-break: break-word;
}
</style>
